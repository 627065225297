import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Container } from "react-bootstrap";
import Layout from "@layout";
import Table from "react-bootstrap/Table";
import SEO from "@components/seo";
import PageBreadcrumb from "../../components/pagebreadcrumb";
import { normalizedData } from "@utils/functions";
import jsonDataTitleInit from "../../data/about/csrTitle/jsonData.json";
import jsonDataTableInit from "../../data/about/csrTable/jsonData.json";
import axios from "axios";
import { useEffect, useState } from "react";
import Preload from "../../components/preloadPage/preload";
const CSR = ({ data, location, pageContext }) => {
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    const [jsonDataTitle, setJsonDataTitle] = useState(jsonDataTitleInit);
    const [jsonDataTable, setJsonDataTable] = useState(jsonDataTableInit);
    const [preload, setPreload] = useState(true);
    useEffect(() => {
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62a6f1e76ac35807d3a2c1c2"
        )
            .then((resultData) => {
                setJsonDataTitle(resultData.data);
                setPreload(false);
            })
            .catch((err) => {
                console.log(err);
                setPreload(false);
            });
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62a6f36e6ac35807d3a2c1fa"
        )
            .then((resultData) => {
                setJsonDataTable(resultData.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            {" "}
            {preload && <Preload />}
            <SEO title="CSR" pathname="/" />
            <PageBreadcrumb
                pageContext={pageContext}
                location={location}
                title="CSR"
            />
            <Container style={{ marginTop: "10px", lineHeight: "130%" }}>
                <h6>{jsonDataTitle.title}</h6>

                <Table striped bordered hover>
                    <tbody>
                        {jsonDataTable.map((tr, j) => {
                            return (
                                <tr key={j}>
                                    <td
                                        colSpan={tr.colSpan}
                                        style={{
                                            verticalAlign: "middle",
                                            fontWeight: "bold",
                                            fontSize: "20px",
                                        }}
                                    >
                                        {tr.tdData[0]}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "middle",
                                            fontWeight: "bold",
                                            fontSize: "20px",
                                        }}
                                    >
                                        {tr.tdData[1]}
                                    </td>
                                    <td>{tr.tdData[2]}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Container>
        </Layout>
    );
};
CSR.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allArticle: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export const query = graphql`
    query CSRQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        allArticle {
            nodes {
                ...Articles
            }
        }
    }
`;

export default CSR;
